import React, { useEffect, useState } from 'react'

import {
  AppActiveGoalsWrapper,
  AppFilterButtonWrapper,
  AppActiveGoalsGrid,
  AppNoGoalsMessage,
  AppFilterHeader,
} from 'components/AppGoalCenter/AppGoalCenter.style'
import { AppGoalCard } from 'components/AppGoalCenter/AppGoalCard/AppGoalCard.component'
import { getRoleFromPath } from 'utils/helper'
import history from 'utils/history'
import { ROLES } from 'config'
import {
  BusinessGoal,
  useGetUserTasksQuery,
  useRegeneratePlanMutation,
  UserTask,
  UserTaskItemStatus,
  UserTaskQuickFilter,
} from '__generated__/api-types-and-hooks'
import {
  BusinessGoalsFilterData,
  OnboardingFlowType,
  useCreateFlowChatSessionMutation,
} from '__generated__/api-types-and-hooks'

import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { AppPlusIcon } from 'components/Common/AppSvgIcons/AppPlusIcon'
import redirectTo from 'utils/redirectTo'
import { ChatBotFlowUrl } from 'components/AppOnboarding/AppChatbot/AppChatBotFlows/constants'
import { getTenantId } from 'utils/getTenantId'
import { AppFilterIcon } from 'components/Common/AppSvgIcons/AppFilterIcon'
import { allTasksTodo, getLatestEndDate } from 'appUtils'
import { isEmpty, isUndefined } from 'lodash'
import { pageRoute } from 'V2/pages/config'
import { GOALIDS, OTHERTASKS } from 'appConfig/enums'
import { calculateGoalProgress } from 'components/AppDataReport/AppData/appUtils'
import { AppFilterMenu } from 'components/Common/AppFilterMenu/AppFilterMenu.component'
import { useClickOutside } from 'hooks/AppuseClickOutside/useClickOutside.hook'
import { goalsFilter, otherGoalIds } from 'appConfig/data'
import AppRegeneratePlanPopup from '../AppRegeneratePlanPopup/AppRegeneratePlanPopup'
import { useFeatureFlag } from 'hooks/userFeatureFlag'

interface IGoalProps {
  goals: BusinessGoal[]
  selectedFilters: BusinessGoalsFilterData
  setSelectedFilters: React.Dispatch<React.SetStateAction<BusinessGoalsFilterData>>
  refetchGoals?: ({ hasFilter }: { hasFilter?: boolean }) => void
  viewArchive: boolean
  setViewArchive: React.Dispatch<React.SetStateAction<boolean>>
  allPlays: BusinessGoal[]
}

export const AppGoals: React.FC<IGoalProps> = ({
  goals,
  selectedFilters,
  setSelectedFilters,
  refetchGoals,
  viewArchive,
  setViewArchive,
  allPlays,
}) => {
  const tenantId = getTenantId()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const userId = useSelector((state: RootState) => state.user.user.id)
  const role = getRoleFromPath()
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)
  const [menuFilters, setMenuFilters] = useState<Record<string, (string | number)[]>>({})
  const [filtersCount, setFiltersCount] = useState(0)
  const [isRegeneratePlanPopupOpen, setIsRegeneratePlanPopupOpen] = useState(false)
  const [regeneratePlanGoal, setRegeneratePlanGoal] = useState<BusinessGoal | null>(null)
  const { mutate: createFlowChatSessionMutate } = useCreateFlowChatSessionMutation()

  const isFieldViewForMiscPlaysEnabled = useFeatureFlag(
    clientId ?? userId,
    'release-field-view-misc-plays'
  )

  const { data: otherTasks } = useGetUserTasksQuery({
    input: {
      userId: clientId ?? userId,
      tenantId,
      filter: { quickFilter: UserTaskQuickFilter.NoProjectAssigned },
    },
  })

  const getFilterCount = (filters: Record<string, any>): number => {
    return Object.values(filters).reduce((count, values) => {
      return Array.isArray(values) ? count + values.length : count
    }, 0)
  }
  useEffect(() => {
    setFiltersCount(getFilterCount(selectedFilters))
    // eslint-disable-next-line
  }, [])

  const { mutate: regeneratePlanMutate, isLoading: isRegeneratingPlan } =
    useRegeneratePlanMutation()
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const goalsPerPage = 6

  // Calculate the index of the last goal on the current page
  const indexOfLastGoal = currentPage * goalsPerPage
  const indexOfFirstGoal = indexOfLastGoal - goalsPerPage
  const currentGoals = goals?.slice(indexOfFirstGoal, indexOfLastGoal)

  // Calculate total pages
  const totalPages = Math.ceil(goals?.length / goalsPerPage)

  // Handler for toggling the archive view
  const handleToggleArchiveView = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      isArchive: !viewArchive,
      statuses: [],
    }))
    setViewArchive(!viewArchive)
  }
  const goalsCount = viewArchive
    ? goals.filter(
        (goal) => goal.name !== OTHERTASKS.MY_OTHER_TASKS && goal.name !== OTHERTASKS.MY_ALL_PLAYS
      ).length
    : goals.length

  const { data: allTasks } = useGetUserTasksQuery({
    input: { tenantId, filter: { quickFilter: UserTaskQuickFilter.AllGoalsTasks } },
  })

  useEffect(() => {
    !isEmpty(menuFilters) && setSelectedFilters({ isArchive: viewArchive, ...menuFilters })
    // eslint-disable-next-line
  }, [isFilterPopupOpen])

  const handleFilterChange = (
    selectedFilters: Record<string, (string | number)[]>,
    filterCount: number
  ) => {
    setMenuFilters(selectedFilters)
    setFiltersCount(filterCount)
  }

  const divRef = useClickOutside(() => setIsFilterPopupOpen(false))
  const statusFilter = goalsFilter.find((filter) => filter.title === 'Status')
  if (statusFilter) {
    // Disable the specific options
    statusFilter.options.forEach((option) => {
      if (
        (option.value === UserTaskItemStatus.Todo ||
          option.value === UserTaskItemStatus.InProgress ||
          option.value === UserTaskItemStatus.Paused) &&
        viewArchive
      ) {
        option.hideOption = true
      } else if (option.value === UserTaskItemStatus.Done && !viewArchive) {
        option.hideOption = true
      } else {
        option.hideOption = false
      }
    })
  }

  return (
    <AppActiveGoalsWrapper>
      <AppFilterHeader>
        <div className="lg:w-[45%]">
          <h3 className="text-3xl font-medium font-inter leading-9 ">
            {viewArchive ? 'Archived Goals' : 'Active Goals'}
          </h3>
          <p className="text-sm font-inter font-normal leading-6 text-app-grey-75">
            {viewArchive
              ? 'Below you will find your archived, declined, or completed goals. Click on each to view details or update the status.'
              : clientId
              ? "Below you will find your client's goals. Click on each to view their progress and collaborate with them on it."
              : ' Below you will find your goals. Click on each to work towards it.'}
          </p>
        </div>

        <AppFilterButtonWrapper>
          <AppButton
            variant="primary"
            size="lg"
            label={clientId ? 'Suggest a Goal' : 'Add Goal'}
            RightIcon={AppPlusIcon}
            onClick={() => {
              createFlowChatSessionMutate(
                {
                  input: {
                    flow: OnboardingFlowType.AddGoal,
                  },
                },
                {
                  onSuccess: (res) => {
                    const tenantId = getTenantId()
                    redirectTo(
                      `/owner-v2/${tenantId}/onboarding/${
                        ChatBotFlowUrl[OnboardingFlowType.AddGoal]
                      }/${res.createFlowChatSession.id}`
                    )
                  },
                }
              )
            }}
          />
          <div className="relative overflow-visible">
            <AppButton
              size="lg"
              variant="primary"
              RightIcon={AppFilterIcon}
              label={`Filters (${filtersCount})`}
              onClick={() => setIsFilterPopupOpen(true)}
            />

            {isRegeneratePlanPopupOpen && (
              <AppRegeneratePlanPopup
                isOpen={isRegeneratePlanPopupOpen}
                goalName={regeneratePlanGoal?.name!}
                onSubmit={(feedback: string) => {
                  setIsRegeneratePlanPopupOpen(false)
                  regeneratePlanMutate(
                    {
                      input: {
                        goalId: regeneratePlanGoal?.goalId!,
                        feedback,
                      },
                    },
                    {
                      onError() {
                        setIsRegeneratePlanPopupOpen(false)
                      },
                      onSuccess() {
                        setIsRegeneratePlanPopupOpen(false)
                        refetchGoals?.({ hasFilter: true })
                        refetchGoals?.({})
                      },
                    }
                  )
                }}
                onCancel={() => {
                  setIsRegeneratePlanPopupOpen(false)
                }}
              />
            )}
          </div>
          {isFilterPopupOpen && (
            <div ref={divRef} className="absolute z-50 top-16 right-0 -translate-x-1/2">
              <AppFilterMenu
                value={selectedFilters as Record<string, (string | number)[]>}
                categories={goalsFilter}
                onFilterChange={handleFilterChange}
              />
            </div>
          )}
          <AppButton
            variant="secondary"
            size="lg"
            label={viewArchive ? 'View Active Goals' : 'View Archive'}
            onClick={handleToggleArchiveView}
          />
        </AppFilterButtonWrapper>
      </AppFilterHeader>

      <AppActiveGoalsGrid>
        {!isUndefined(otherTasks) && allPlays && currentGoals?.length > 0
          ? currentGoals
              .filter(
                (goal) =>
                  !(
                    viewArchive &&
                    (goal.name === OTHERTASKS.MY_OTHER_TASKS ||
                      goal.name === OTHERTASKS.MY_ALL_PLAYS)
                  )
              )
              .map((goal) => {
                const isMyOtherTask = goal.name === OTHERTASKS.MY_OTHER_TASKS
                const isAllTasks = goal.name === OTHERTASKS.MY_ALL_PLAYS
                const progress = isMyOtherTask
                  ? calculateGoalProgress(otherTasks?.getUserTasks!)
                  : isAllTasks
                  ? calculateGoalProgress(allTasks?.getUserTasks!)
                  : calculateGoalProgress(goal.plan?.tasks as UserTask[])
                const expectedEndDate = isMyOtherTask
                  ? getLatestEndDate(otherTasks?.getUserTasks)
                  : isAllTasks
                  ? getLatestEndDate(allPlays)
                  : goal.expectedEndDate!

                const handleClick = () => {
                  if (viewArchive || goal.status === UserTaskItemStatus.Creating) return
                  const basePath = `/${role}/${tenantId}`
                  const getPath = () => {
                    return role?.includes(ROLES.BUSINESS_OWNER)
                      ? isFieldViewForMiscPlaysEnabled &&
                        otherGoalIds.includes(goal.goalId as GOALIDS)
                        ? `${basePath}/${goal.goalId}/task`
                        : `${basePath}/goals/${goal.goalId}/${pageRoute.gameMode}`
                      : isFieldViewForMiscPlaysEnabled &&
                        otherGoalIds.includes(goal.goalId as GOALIDS)
                      ? `${basePath}/clients/${clientId}/${goal.goalId}/task`
                      : `${basePath}/clients/${clientId}/goals/${goal.goalId}/${pageRoute.gameMode}`
                  }
                  history.push(getPath())
                }

                return (
                  <AppGoalCard
                    key={goal.goalId}
                    progress={progress}
                    goalId={goal.goalId!}
                    onClick={handleClick}
                    logo={goal.logo ?? ''}
                    goalStatus={goal.status}
                    category={goal.category!}
                    refetchGoals={refetchGoals}
                    isMyOtherTask={isMyOtherTask || isAllTasks}
                    description={isMyOtherTask || isAllTasks ? goal?.description! : ''}
                    isLoading={
                      (isRegeneratingPlan && goal.goalId === regeneratePlanGoal?.goalId) || false
                    }
                    priority={goal.goalPriority!}
                    expectedEndDate={expectedEndDate}
                    allTaskTodo={allTasksTodo(goal.plan)}
                    title={goal.name || 'No goal name provided'}
                    goal={goal}
                    openRegeneratePlanPopup={() => {
                      setIsRegeneratePlanPopupOpen(true)
                      setRegeneratePlanGoal(goal)
                    }}
                  />
                )
              })
          : null}
      </AppActiveGoalsGrid>

      {goalsCount === 0 &&
        (viewArchive ? (
          <AppNoGoalsMessage>No Archived Goals</AppNoGoalsMessage>
        ) : (
          <AppNoGoalsMessage>No Active Goals</AppNoGoalsMessage>
        ))}
      <div className="flex justify-center w-full">
        <div className="flex gap-2">
          <AppButton
            variant="clear"
            size="xs"
            label="Previous"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <AppButton
              key={index}
              variant="clear"
              size="xs"
              label={`${index + 1}`}
              onClick={() => setCurrentPage(index + 1)}
            />
          ))}
          <AppButton
            variant="clear"
            size="xs"
            label="Next"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
        </div>
      </div>
    </AppActiveGoalsWrapper>
  )
}
