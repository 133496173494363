import React, { useEffect, useRef, useState } from 'react'
import moment, { Moment } from 'moment'
import { isEmpty, pickBy } from 'lodash'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import uuid from 'react-uuid'

import {
  UserTask,
  UserTaskSource,
  useCreateUserTaskMutation,
  useSearchHowToDoTaskMutation,
  useUpdateUserTaskMutation,
  BusinessGoalPriority,
  UserTaskItemStatus,
  useUpdateBusinessGoalMutation,
  useEstimateTaskDurationMutation,
} from '__generated__/api-types-and-hooks'
import hookForms from 'utils/hookForms'
import { FORM, GOALIDS } from 'appConfig/enums'
import { formKeys, otherGoalIds } from 'appConfig/data'
import useFormContainer from 'hooks/useFormContainer'
import { setFormData } from 'store/actions/form'
import { userTaskForm } from 'appUtils/appValidation'
import {
  calculateNewGoalEndDate,
  calculateTotalEstimationHoursOfUserTasks,
  calculateWorkingDaysBetweenTwoDates,
  isDateInCurrentWeekAndPast,
} from 'appUtils'

interface useUserTaskFormProps {
  task?: UserTask
  plan?: {
    name: string | null | undefined
    id: string | null | undefined
    planExpectedEndDate: string | null | undefined
    startDate?: string
  }
  hoursSpentPerDay: number
  tenantId: string
  userId: string
  allTasks?: UserTask[]
  goalId?: string
  advisorsData: Array<{ label: any; value: any }>
  source: UserTaskSource
  setShowTaskModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowTaskSuccessModal?: React.Dispatch<React.SetStateAction<boolean>>
  setShowTaskErrorModal?: React.Dispatch<React.SetStateAction<boolean>>
  setCreatedUserTask?: React.Dispatch<React.SetStateAction<UserTask>>
  refetch: () => void
  goalEndDate: string
}

export const useUserTaskForm = ({
  task,
  plan,
  goalEndDate,
  hoursSpentPerDay,
  tenantId,
  userId,
  allTasks,
  goalId,
  advisorsData,
  source,
  setShowTaskModal,
  setShowTaskSuccessModal,
  setShowTaskErrorModal,
  setCreatedUserTask,
  refetch,
}: useUserTaskFormProps) => {
  const isViewMode = Boolean(task)
  const [generatedHowToDo, setGeneratedHowToDo] = useState('')
  const [tutorialNotFound, setTutorialNotFound] = useState(false)
  const [showWarningPopup, setShowWarningPopup] = useState<
    | 'effortPopup'
    | 'moreThanCapacityPopup'
    | 'startDateOutsideGoalTimelinePopup'
    | 'endDateOutsideGoalTimelinePopup'
    | 'none'
  >('none')
  const [goalExpectedEndDate, setGoalExpectedEndDate] = useState<string>(goalEndDate ?? '')
  const planStartDate = useRef(plan?.startDate)
  const [taskStartDate, setTaskStartDate] = useState<string>(
    task?.startDate ?? moment().format('YYYY-MM-DD')
  )
  const [clickedFields, setClickedFields] = useState<{ [key: string]: boolean }>({
    taskPriority: false,
    status: false,
    milestoneId: false,
    clickedFieldsategory: false,
    startDate: false,
    expectedEndDate: false,
    estimatedEffortInMinutes: false,
    assigneeId: false,
    name: false,
    instructionForTask: false,
    goalId: false,
  })
  const [isStartDateBeforePlanStartDate, setIsStartDateBeforePlanStartDate] = useState(false)
  const isAllTasks = otherGoalIds.includes(goalId as GOALIDS)
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(userTaskForm),
  })
  const {
    setValue,
    trigger,
    formState: { errors },
  } = methods
  useFormContainer(FORM.USER_TASK_FORM, methods)
  const {
    mutate: createUserTask,
    isLoading: isCreateTaskLoading,
    isSuccess: isCreateTaskSuccess,
    isError: isCreateTaskError,
  } = useCreateUserTaskMutation()

  const { mutate: updateUserTask } = useUpdateUserTaskMutation()
  const { mutate: updateGoal } = useUpdateBusinessGoalMutation()
  const { mutate: estimateTaskDuration } = useEstimateTaskDurationMutation()

  const shouldShowWarningPopup = (
    allTasks: UserTask[],
    expectedDate: Moment,
    goalExpectedEndDate: Moment,
    currentTaskEstimatedHours: number
  ): boolean => {
    const workingDays = calculateWorkingDaysBetweenTwoDates(expectedDate, goalExpectedEndDate)
    const tasksHoursAfterExpextedDate = calculateTotalEstimationHoursOfUserTasks(
      allTasks,
      expectedDate,
      task?.taskId ?? ''
    )
    const availableHours = workingDays * hoursSpentPerDay
    // Calculate hours difference once and use it for setting days increased and condition checks
    const freeHours = availableHours - tasksHoursAfterExpextedDate
    // Show warning if available hours are less than total task hours or if the task estimation exceeds remaining hours
    const showWarning =
      availableHours < tasksHoursAfterExpextedDate || currentTaskEstimatedHours > freeHours
    if (showWarning) {
      // Calculate additional days needed to cover the shortfall
      let hoursToBeAdded = currentTaskEstimatedHours - freeHours
      if (expectedDate.isAfter(goalExpectedEndDate, 'days')) {
        const numberOfDaysToBeIncreased =
          calculateWorkingDaysBetweenTwoDates(goalExpectedEndDate, expectedDate) - 2
        hoursToBeAdded += numberOfDaysToBeIncreased * hoursSpentPerDay
      }
      const newEndDate = calculateNewGoalEndDate(
        goalExpectedEndDate,
        hoursToBeAdded,
        hoursSpentPerDay
      )
      setGoalExpectedEndDate(newEndDate)
    }
    return showWarning
  }

  const handleClick = (field: string, isHovered: boolean) => {
    setClickedFields((prev) => ({ ...prev, [field]: isHovered }))
    if (isHovered) return
    const data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()
    const startDate = moment(data.startDate)
    const previousStartDate = moment(taskStartDate)
    if (
      field === 'name' &&
      !isHovered &&
      !task?.estimatedEffortInMinutes &&
      data.name &&
      !isViewMode
    ) {
      estimateTaskDuration(
        {
          taskName: data.name,
        },
        {
          onSuccess: (data) => {
            setValue('estimatedEffortInMinutes', data.estimateTaskDuration.estimatedMinutes)
          },
        }
      )
    }

    if (field === 'startDate' && data.startDate && !errors.startDate) {
      if (isViewMode) {
        const diff = startDate.diff(previousStartDate, 'days')
        !startDate.isSame(previousStartDate) &&
          setValue(
            'expectedEndDate',
            moment(data.expectedEndDate).add(diff, 'days').format('YYYY-MM-DD')
          )
      } else {
        setValue('expectedEndDate', data.startDate)
      }
      trigger('expectedEndDate')
    }

    if (
      planStartDate.current &&
      data.startDate &&
      startDate.isBefore(moment(planStartDate.current))
    ) {
      setIsStartDateBeforePlanStartDate(true)
    } else {
      setIsStartDateBeforePlanStartDate(false)
    }
    if (field === 'estimatedEffortInMinutes') checkHourWarning()
    if (isViewMode) {
      const shouldShowWarning = checkWarningPopup(field)

      if (!shouldShowWarning && isEmpty(errors)) {
        updateTaskData()
      }
    }
  }

  const checkWarningPopup = (field: string): boolean => {
    if (field === 'startDate' || field === 'expectedEndDate' || field === 'goalId') {
      return checkDateWarning()
    }
    return false
  }

  const checkDateWarning = (): boolean => {
    const data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()
    if (!data.planId) return false
    const endDateofGoal = !isEmpty(data.goalExpectedEndDate)
      ? moment(data.goalExpectedEndDate)
      : moment(goalExpectedEndDate)
    const currentTaskEstimatedHours = getHoursCeiling(data.estimatedEffortInMinutes)
    const startDate = moment(data.startDate)
    const expectedEndDate = moment(data.expectedEndDate)

    if (startDate.isAfter(endDateofGoal)) {
      setShowWarningPopup('startDateOutsideGoalTimelinePopup')
      return true
    }

    if (expectedEndDate.isAfter(endDateofGoal)) {
      setGoalExpectedEndDate(expectedEndDate.format('YYYY-MM-DD'))
      setShowWarningPopup('endDateOutsideGoalTimelinePopup')
      return true
    }
    if (
      !isDateInCurrentWeekAndPast(data.startDate) &&
      shouldShowWarningPopup(allTasks ?? [], startDate, endDateofGoal, currentTaskEstimatedHours)
    ) {
      setShowWarningPopup('moreThanCapacityPopup')
      return true
    }
    return false
  }

  const checkHourWarning = (): boolean => {
    const data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()
    const currentTaskestimatedHours = getHoursCeiling(data.estimatedEffortInMinutes)
    if (currentTaskestimatedHours > 8) {
      setShowWarningPopup('effortPopup')
      return true
    }
    return false
  }

  const updateTaskData = () => {
    let data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()
    let taskData = data
    delete taskData.goalExpectedEndDate
    delete taskData.milestoneId
    delete taskData.isOverDue
    setTaskStartDate(taskData.startDate)
    updateUserTask({
      input: { ...taskData, taskId: task?.taskId, tenantId, userId },
    })
  }

  const handleGoalEndDateUpdate = () => {
    let data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()
    updateGoal({
      input: {
        expectedEndDate: goalExpectedEndDate,
        goalId: data.goalId ?? '',
        tenantId,
      },
    })
    isViewMode ? updateTaskData() : handlCreateTask()
    setValue('goalExpectedEndDate', goalExpectedEndDate)
    setShowWarningPopup('none')
  }
  const dispatch = useDispatch()
  useEffect(() => {
    if (advisorsData.length > 0) {
      let initialValues = {
        planId: plan?.id,
        assigneeId: userId,
        status: UserTaskItemStatus.Todo,
        estimatedEffortInMinutes: 0,
        taskPriority: BusinessGoalPriority.MediumPriority,
        goalId: !isAllTasks && goalId ? goalId : GOALIDS.MY_OTHER_TASKS,
        goalExpectedEndDate: goalEndDate,
      }
      const formData = pickBy(
        task
          ? {
              ...task,
              assigneeId: task?.assigneeId ?? task?.userId,
              goalId: task.goalId ?? GOALIDS.MY_OTHER_TASKS,
              goalExpectedEndDate: goalEndDate,
            }
          : initialValues,
        (value, key) => formKeys[FORM.USER_TASK_FORM].includes(key)
      )
      dispatch(setFormData({ form: [FORM.USER_TASK_FORM], data: formData }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisorsData])

  const handlCreateTask = () => {
    let data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()
    delete data.goalExpectedEndDate
    delete data.milestoneId
    const userTaskDetails: UserTask = {
      name: data.name,
      instructionForTask: data.instructionForTask,
      category: data.category,
      taskPriority: data.taskPriority,
      assigneeId: data.assigneeId,
    }
    setCreatedUserTask && setCreatedUserTask(userTaskDetails)
    createUserTask({ input: { ...data, source, tenantId, userId } })
  }

  const handleTaskSubmission = () => {
    if (isEmpty(errors) && !checkDateWarning() && !isViewMode) {
      handlCreateTask()
    }
  }
  const {
    mutate: searchHowToDoTask,
    isSuccess: isSearchHowToDoTaskSuccess,
    data: searchHowToDoTaskData,
    isLoading: isSearchHowToDoTaskLoading,
  } = useSearchHowToDoTaskMutation()

  useEffect(() => {
    if (isSearchHowToDoTaskSuccess) {
      const howToData = {
        howToId: uuid(),
        title: searchHowToDoTaskData.searchHowToDoTask.text,
        url: JSON.stringify(searchHowToDoTaskData.searchHowToDoTask.videoLinks),
      }
      if (
        !searchHowToDoTaskData.searchHowToDoTask.text &&
        searchHowToDoTaskData.searchHowToDoTask.videoLinks.length === 0
      ) {
        setTutorialNotFound(true)
      }
      setValue('howToLinks', [howToData])
      setValue('howToDo', searchHowToDoTaskData.searchHowToDoTask.text)
      setGeneratedHowToDo(searchHowToDoTaskData.searchHowToDoTask.text)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchHowToDoTaskSuccess])

  useEffect(() => {
    if (isCreateTaskSuccess) {
      setShowTaskModal(false)
      setShowTaskSuccessModal && setShowTaskSuccessModal(true)
      refetch()
    }

    if (isCreateTaskError) {
      setShowTaskModal(false)
      setShowTaskErrorModal && setShowTaskErrorModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateTaskSuccess, isCreateTaskError])

  return {
    isViewMode,
    generatedHowToDo,
    showWarningPopup,
    setShowWarningPopup,
    goalExpectedEndDate,
    setGoalExpectedEndDate,
    planStartDate,
    methods,
    tutorialNotFound,
    clickedFields,
    isCreateTaskLoading,
    isCreateTaskSuccess,
    isCreateTaskError,
    handleClick,
    updateTaskData,
    handleGoalEndDateUpdate,
    handleTaskSubmission,
    handlCreateTask,
    searchHowToDoTask,
    isSearchHowToDoTaskLoading,
    isSearchHowToDoTaskSuccess,
    isStartDateBeforePlanStartDate,
  }
}

export const getHoursCeiling = (timeInMinutes) => {
  return timeInMinutes > 0 ? Math.ceil(timeInMinutes / 60) : 0
}
